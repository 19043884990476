"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getErrorBranchErrorCodeDescription = exports.createNumberRange = exports.mapBranchICHomeQuotesToQuoteServiceHomeQuotes = void 0;
const grins_utils_1 = require("grins-utils");
const index_1 = require("../../index");
const non_normal_types_1 = require("../../non-normal-types");
function mapBranchICHomeQuotesToQuoteServiceHomeQuotes(branchICHomeQuoteResponse, omsiteUrl, carriers) {
    const branchCarrierId = "1001";
    const carrier = (0, index_1.getCarrierData)(branchCarrierId, carriers);
    if (!carrier) {
        throw new Error(`Carrier fetch for carrier id: ${branchCarrierId} failed.`);
    }
    const carrierName = carrier.name;
    const carrierLogo = omsiteUrl + carrier?.logo_url;
    const offerWasRejected = branchICHomeQuoteResponse.requestQuote.quote.offerings
        .homeownersRejectCode !== null;
    if (offerWasRejected) {
        const errorCodeDescription = getErrorBranchErrorCodeDescription(branchICHomeQuoteResponse.requestQuote.quote.offerings
            .homeownersRejectCode);
        const mappedFailedHomeQuote = [
            {
                status: non_normal_types_1.QuoteStatus.Failed,
                carrierId: "1001",
                quoteId: null,
                costPerYear: null,
                deductible: null,
                dwellingCoverage: null,
                otherStructuresCoverage: null,
                personalPropertyCoverage: null,
                lossOfUseCoverage: null,
                personalLiabilityCoverage: null,
                medicalPaymentsCoverage: null,
                consumerUrl: "",
                agentUrl: "",
                carrierLogo,
                carrierName,
                quoteType: non_normal_types_1.QuoteServicePolicyType.ho3,
                statusMessage: errorCodeDescription,
                term: null,
                tier: null,
                bridgeQuote: false,
                externalId: "",
                effectiveDate: "",
            },
        ];
        return mappedFailedHomeQuote;
    }
    const homePremiumOptions = branchICHomeQuoteResponse.requestQuote.options.filter(option => option.type === non_normal_types_1.BranchPolicyType.home && !isNaN(option.homePremium));
    const { coverageA, coverageBPctOfA, coverageCPctOfA, coverageDPctOfA, coverageX, coverageY, deductibleAllOther, } = branchICHomeQuoteResponse.requestQuote.quote.homeCoverage;
    return homePremiumOptions.map(option => {
        const mappedSuccessfulHomeQuote = {
            status: non_normal_types_1.QuoteStatus.Succeeded,
            carrierId: "1001",
            quoteId: branchICHomeQuoteResponse.requestQuote.id,
            costPerYear: option.homePremium,
            deductible: deductibleAllOther,
            dwellingCoverage: coverageA,
            otherStructuresCoverage: (coverageBPctOfA / 100) * coverageA,
            personalPropertyCoverage: (coverageCPctOfA / 100) * coverageA,
            lossOfUseCoverage: (coverageDPctOfA / 100) * coverageA,
            personalLiabilityCoverage: coverageX,
            medicalPaymentsCoverage: coverageY,
            consumerUrl: branchICHomeQuoteResponse.requestQuote.branchOfferUrl,
            agentUrl: branchICHomeQuoteResponse.requestQuote.branchAgentUrl,
            carrierLogo,
            carrierName,
            quoteType: non_normal_types_1.QuoteServicePolicyType.ho3,
            statusMessage: "Successfully retrieved quote from Branch",
            term: 12,
            tier: null,
            bridgeQuote: false,
            externalId: branchICHomeQuoteResponse.requestQuote.id,
            effectiveDate: (0, grins_utils_1.resetPastStringDateToFutureISODate)(branchICHomeQuoteResponse.requestQuote.quote.home.purchaseDate, 7) || "",
        };
        return mappedSuccessfulHomeQuote;
    });
}
exports.mapBranchICHomeQuotesToQuoteServiceHomeQuotes = mapBranchICHomeQuotesToQuoteServiceHomeQuotes;
const errorMessages = new Map([
    [
        "Generic / not-easily-classified error. Sometimes returned on invalid input that is not easily identified or expected.",
        new Set([4000, 4001]),
    ],
    [
        "One of the data services that Branch relies upon is not available.",
        new Set([4002, 5014]),
    ],
    [
        "Branch is not selling insurance in the requested state via this API",
        new Set([4003, 5017, 5026]),
    ],
    [
        "Could not find name and address combination in databases; submit prior address (which could be current address if insured is buying a new home)",
        new Set([5001]),
    ],
    [
        "Could not find name/address/prior address combination in databases; submit date of birth and it will be possible to proceed",
        new Set([5002]),
    ],
    [
        "Could not find current auto insurance details; submit all auto insurance details in the QuoteInput type to get a price",
        new Set([5003, 5008]),
    ],
    [
        "Could not find home details; submit all home characteristics in the HomeDetailsInput type to get a homeowners price",
        new Set([5004, 50006]),
    ],
    [
        "Could not find any valid vehicles; submit VIN to get an auto price",
        new Set([5005, 5006, 5009, 5010, 50001]),
    ],
    [
        "Address is currently in a moratorium location and not available for insurance at the moment.",
        new Set([5011]),
    ],
    [
        "Unfortunately, we are unable to give you a price at this time",
        new Set([5027]),
    ],
    [
        "Need primary insured’s driver’s license state and number to proceed",
        new Set([5012, 5015, 5016]),
    ],
    [
        "We do not accept PO Box addresses; need to resubmit with a real property address",
        new Set([5013]),
    ],
    ["Rater error", new Set([5014])],
    ["Fee engine error", new Set([5018])],
    [
        "Request triggered suspicious-looking-activity rules",
        new Set([5019, 5021]),
    ],
    ["Request lacked enough valid information to proceed", new Set([5020])],
    [
        "Past prior-customer history requires speaking to Branch member support",
        new Set([5022]),
    ],
    ["Need lat/lng to proceed for a home price", new Set([5023])],
    ["Invalid zip code", new Set([5024])],
    ["Need apt/unit number", new Set([5025])],
    [
        "Unable to offer an options on the initial requestQuote(RC1) call; you should re-call requestQuote with different details if trying to cure",
        new Set([5032]),
    ],
    [
        "Tried to recalculate a quote that had no options (was not eligible for anything from Branch); please start from scratch",
        new Set([5033]),
    ],
    [
        "This quote returned with an auxiliary auto rate, we are unable to offer a quote at this time.",
        new Set([5034]),
    ],
    [
        "We block simultaneous requests for the same name and address. Please wait 30 seconds and try again, and do not double-click to submit.",
        new Set([5035]),
    ],
    [
        "Property claims history makes the home ineligible for Branch",
        new Set([10009, 10010, 10011, 10012]),
    ],
    [
        "Driver(s) claims and violations history makes the auto ineligible for Branch",
        new Set([10013, 10014, 10015, 10016, 20001, 20002, 20003, 20004]),
    ],
    [
        "Square footage is more than Branch will write for homeowners",
        new Set([50002]),
    ],
    [
        "Occupant count is more than Branch will write for homeowners",
        new Set([50003]),
    ],
    [
        "The car-to-driver ratio is more than Branch will write for auto",
        new Set([50004]),
    ],
    [
        "The home was built before Branch's threshold for this state.",
        new Set([50005]),
    ],
    ["No prior / not recent enough auto insurance", new Set([50007])],
    ["Branch will not write EIFS", new Set([50008])],
    ["Branch will not write homes on stilts", new Set([50009])],
    ["Branch will not write wood roofs", new Set([50010])],
    [
        "Replacement cost estimate is below what Branch will write",
        new Set([50011]),
    ],
    [
        "Replacement cost estimate is above what Branch will write",
        new Set([50012]),
    ],
    [
        "Branch will not write homes with this fire protection class in this state",
        new Set([50013, 50028]),
    ],
    ["No fire protection class found on property", new Set([50014])],
    [
        "Driver count is more than Branch will write on a single policy",
        new Set([50015]),
    ],
    [
        "Car count is more than Branch will write on a single policy",
        new Set([50016]),
    ],
    [
        "Property is in a coastal location that Branch does not write",
        new Set([50018]),
    ],
    [
        "Did not receive Fireline score (in a state with significant wildfire risk)",
        new Set([50019]),
    ],
    [
        "Fireline score (in states with significant wildfire risk) was too high",
        new Set([50020]),
    ],
    [
        "Fireline score, in combination with the fire protection class, was too high",
        new Set([50021]),
    ],
    [
        "Did not receive distance-to-coast and need it for eligibility",
        new Set([50022]),
    ],
    ["Ineligible distance-to-coast", new Set([50023])],
    [
        "A Scheduled Personal Property item is more than 10 percent of Coverage C",
        new Set([50024]),
    ],
    [
        "A Scheduled Personal Property items is more than 10 percent of Coverage A",
        new Set([50025]),
    ],
    [
        "The aggregate of all Scheduled Personal Property items is more than 50 percent of Coverage C",
        new Set([50026]),
    ],
    ["No wind/hail exclusion is available", new Set([50027])],
    ["Auto rejection when auto was not requested", new Set([50029])],
    [
        "Roof condition, as scored through aerial imagery, looks ineligible. Provide updated images of roof to Branch to proceed.",
        new Set([50030]),
    ],
    [
        "Yard debris, as scored through aerial imagery, looks ineligible. Provide updated images of yard to Branch to proceed.",
        new Set([50031]),
    ],
    ["Ineligible coastal county location", new Set([50032])],
    ["Insufficient continuous coverage", new Set([50033])],
    ["Invalid auto insurance history", new Set([50034, 50037, 50038, 50040])],
    ["Invalid accident and violation history", new Set([50035])],
    ["Too many cars for number of drivers", new Set([50036])],
    ["Invalid excluded drivers", new Set([50039])],
    ["Self-reported underwriting disqualification (home)", new Set([50041])],
    ["Self-reported underwriting disqualification (auto)", new Set([50042])],
    ["Florida-specific rejections", new Set(createNumberRange(50043, 50082))],
    ["Condo is rented out", new Set([50083])],
    ["Other home rejection", new Set([50084])],
    ["Other condo rejection", new Set([50085])],
    ["Other auto rejection", new Set([50086])],
    ["Primary named insured is too young", new Set([50087])],
    [
        "We are not currently writing secondary homes in this state",
        new Set([50088]),
    ],
    ["Home must be clean of claims in this state at this time", new Set([50089])],
    [
        "Bundle requested via API; one policy type was rejected, so also rejecting the other requested type",
        new Set(createNumberRange(50090, 50093)),
    ],
    [
        "Branch has too much total insured value near this property to make an offer",
        new Set([50095]),
    ],
    [
        "Property mismatch between what was requested and what Cherre returned (e.g. Asked for Home and Cherre returned Condo)",
        new Set([50096]),
    ],
    [
        "The property is registered as a Commercial property in Cherre",
        new Set([50097]),
    ],
    [
        "Unsupported property type (not included in 50096 and 50097) according to Cherre records",
        new Set([50098]),
    ],
]);
/**
 * Creates an array of numbers within a specified range. (inclusive of start and end)
 *
 * @param {number} start The start of the range.
 * @param {number} end The end of the range.
 * @returns {number[]} Returns the range of numbers.
 */
function createNumberRange(start, end) {
    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
}
exports.createNumberRange = createNumberRange;
/**
 * Retrieves the error code description for a given error code.
 *
 * @param {number | string} code The error code.
 * @returns {string} Returns the error code description.
 */
function getErrorBranchErrorCodeDescription(code) {
    code = Number(code);
    for (const [message, codes] of errorMessages) {
        if (codes.has(code)) {
            return message;
        }
    }
    return "Error code not found.";
}
exports.getErrorBranchErrorCodeDescription = getErrorBranchErrorCodeDescription;
