"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.resetPastStringDateToFutureISODate = exports.resetPastLuxonDateToFutureDate = exports.toKiloUnits = exports.formatCurrency = exports.UsStateJsMap = exports.UsState = exports.isValidXml = exports.companyFromEspEventEnv = exports.getAuthTokenV2 = exports.getAuthToken = exports.parseAddressThrowNoErrors = exports.parseAddress = exports.allowCors = exports.prepareValidationErrorObject = exports.formatJoiErrors = void 0;
const fast_xml_parser_1 = require("fast-xml-parser");
const grins_types_1 = require("grins-types");
const luxon_1 = require("luxon");
var helpers_1 = require("./helpers");
Object.defineProperty(exports, "formatJoiErrors", { enumerable: true, get: function () { return helpers_1.formatJoiErrors; } });
Object.defineProperty(exports, "prepareValidationErrorObject", { enumerable: true, get: function () { return helpers_1.prepareValidationErrorObject; } });
var koa_middleware_1 = require("./koa-middleware");
Object.defineProperty(exports, "allowCors", { enumerable: true, get: function () { return koa_middleware_1.allowCors; } });
var parse_address_1 = require("./parse-address");
Object.defineProperty(exports, "parseAddress", { enumerable: true, get: function () { return parse_address_1.parseAddress; } });
Object.defineProperty(exports, "parseAddressThrowNoErrors", { enumerable: true, get: function () { return parse_address_1.parseAddressThrowNoErrors; } });
var okta_utils_1 = require("./okta-utils");
Object.defineProperty(exports, "getAuthToken", { enumerable: true, get: function () { return okta_utils_1.getAuthToken; } });
Object.defineProperty(exports, "getAuthTokenV2", { enumerable: true, get: function () { return okta_utils_1.getAuthTokenV2; } });
__exportStar(require("./date-time-utils"), exports);
function companyFromEspEventEnv(espEventEnv) {
    let company = grins_types_1.CompanyName.gri;
    switch (espEventEnv) {
        case grins_types_1.EspEventEnv.griDev:
        case grins_types_1.EspEventEnv.griProd:
            company = grins_types_1.CompanyName.gri;
            break;
        case grins_types_1.EspEventEnv.prateDev:
        case grins_types_1.EspEventEnv.prateProd:
            company = grins_types_1.CompanyName.prate;
            break;
        case grins_types_1.EspEventEnv.citywideDev:
        case grins_types_1.EspEventEnv.citywideProd:
            company = grins_types_1.CompanyName.citywide;
            break;
        case grins_types_1.EspEventEnv.opDev:
        case grins_types_1.EspEventEnv.opProd:
            company = grins_types_1.CompanyName.op;
            break;
        default:
            throw new Error(`Cannot get company name from invalid ESP Event, Env variable = '${espEventEnv}'`);
    }
    return company;
}
exports.companyFromEspEventEnv = companyFromEspEventEnv;
function isValidXml(xmlToValidate) {
    const validationResults = fast_xml_parser_1.XMLValidator.validate(xmlToValidate);
    if (typeof validationResults !== "boolean") {
        return { valid: false, ...validationResults };
    }
    return { valid: true };
}
exports.isValidXml = isValidXml;
// values taken from https://www.ssa.gov/international/coc-docs/states.html
// also from https://pe.usps.com/text/pub28/28apb.htm
var UsState;
(function (UsState) {
    UsState["armedForcesAmerica"] = "AA";
    UsState["armedForcesEurope"] = "AE";
    UsState["alabama"] = "AL";
    UsState["alaska"] = "AK";
    UsState["armedForcesPacific"] = "AP";
    UsState["americanSamoa"] = "AS";
    UsState["arizona"] = "AZ";
    UsState["arkansas"] = "AR";
    UsState["california"] = "CA";
    UsState["colorado"] = "CO";
    UsState["connecticut"] = "CT";
    UsState["delaware"] = "DE";
    UsState["districtOfColumbia"] = "DC";
    UsState["florida"] = "FL";
    UsState["federatedStatesOfMicronesia"] = "FM";
    UsState["georgia"] = "GA";
    UsState["guam"] = "GU";
    UsState["hawaii"] = "HI";
    UsState["idaho"] = "ID";
    UsState["illinois"] = "IL";
    UsState["indiana"] = "IN";
    UsState["iowa"] = "IA";
    UsState["kansas"] = "KS";
    UsState["kentucky"] = "KY";
    UsState["louisiana"] = "LA";
    UsState["maine"] = "ME";
    UsState["maryland"] = "MD";
    UsState["massachusetts"] = "MA";
    UsState["marshallIslands"] = "MH";
    UsState["michigan"] = "MI";
    UsState["minnesota"] = "MN";
    UsState["mississippi"] = "MS";
    UsState["missouri"] = "MO";
    UsState["northernMarianaIslands"] = "MP";
    UsState["montana"] = "MT";
    UsState["nebraska"] = "NE";
    UsState["nevada"] = "NV";
    UsState["newHampshire"] = "NH";
    UsState["newJersey"] = "NJ";
    UsState["newMexico"] = "NM";
    UsState["newYork"] = "NY";
    UsState["northCarolina"] = "NC";
    UsState["northDakota"] = "ND";
    UsState["ohio"] = "OH";
    UsState["oklahoma"] = "OK";
    UsState["oregon"] = "OR";
    UsState["pennsylvania"] = "PA";
    UsState["puertoRico"] = "PR";
    UsState["palau"] = "PW";
    UsState["rhodeIsland"] = "RI";
    UsState["southCarolina"] = "SC";
    UsState["southDakota"] = "SD";
    UsState["tennessee"] = "TN";
    UsState["texas"] = "TX";
    UsState["utah"] = "UT";
    UsState["vermont"] = "VT";
    UsState["virginia"] = "VA";
    UsState["virginIslands"] = "VI";
    UsState["washington"] = "WA";
    UsState["westVirginia"] = "WV";
    UsState["wisconsin"] = "WI";
    UsState["wyoming"] = "WY";
})(UsState || (exports.UsState = UsState = {}));
exports.UsStateJsMap = new Map([
    ["armed forces america", UsState.armedForcesAmerica],
    ["AA", UsState.armedForcesAmerica],
    ["armed forces europe", UsState.armedForcesEurope],
    ["AE", UsState.armedForcesEurope],
    ["alabama", UsState.alabama],
    ["AL", UsState.alabama],
    ["alaska", UsState.alaska],
    ["armed forces pacific", UsState.armedForcesPacific],
    ["AP", UsState.armedForcesAmerica],
    ["AK", UsState.alaska],
    ["american samoa", UsState.americanSamoa],
    ["AS", UsState.americanSamoa],
    ["arizona", UsState.arizona],
    ["AZ", UsState.arizona],
    ["arkansas", UsState.arkansas],
    ["AR", UsState.arkansas],
    ["california", UsState.california],
    ["CA", UsState.california],
    ["colorado", UsState.colorado],
    ["CO", UsState.colorado],
    ["connecticut", UsState.connecticut],
    ["CT", UsState.connecticut],
    ["delaware", UsState.delaware],
    ["DE", UsState.delaware],
    ["district of columbia", UsState.districtOfColumbia],
    ["DC", UsState.districtOfColumbia],
    ["florida", UsState.florida],
    ["FL", UsState.florida],
    ["federated states of micronesia", UsState.federatedStatesOfMicronesia],
    ["FM", UsState.federatedStatesOfMicronesia],
    ["georgia", UsState.georgia],
    ["GA", UsState.georgia],
    ["guam", UsState.guam],
    ["GU", UsState.guam],
    ["hawaii", UsState.hawaii],
    ["HI", UsState.hawaii],
    ["idaho", UsState.idaho],
    ["ID", UsState.idaho],
    ["illinois", UsState.illinois],
    ["IL", UsState.illinois],
    ["indiana", UsState.indiana],
    ["IN", UsState.indiana],
    ["iowa", UsState.iowa],
    ["IA", UsState.iowa],
    ["kansas", UsState.kansas],
    ["KS", UsState.kansas],
    ["kentucky", UsState.kentucky],
    ["KY", UsState.kentucky],
    ["louisiana", UsState.louisiana],
    ["LA", UsState.louisiana],
    ["maine", UsState.maine],
    ["ME", UsState.maine],
    ["maryland", UsState.maryland],
    ["MD", UsState.maryland],
    ["massachusetts", UsState.massachusetts],
    ["MA", UsState.massachusetts],
    ["marshall islands", UsState.marshallIslands],
    ["MH", UsState.marshallIslands],
    ["michigan", UsState.michigan],
    ["MI", UsState.michigan],
    ["minnesota", UsState.minnesota],
    ["MN", UsState.minnesota],
    ["northern mariana islands", UsState.northernMarianaIslands],
    ["MP", UsState.northernMarianaIslands],
    ["mississippi", UsState.mississippi],
    ["MS", UsState.mississippi],
    ["missouri", UsState.missouri],
    ["MO", UsState.missouri],
    ["montana", UsState.montana],
    ["MT", UsState.montana],
    ["nebraska", UsState.nebraska],
    ["NE", UsState.nebraska],
    ["nevada", UsState.nevada],
    ["NV", UsState.nevada],
    ["new hampshire", UsState.newHampshire],
    ["NH", UsState.newHampshire],
    ["new jersey", UsState.newJersey],
    ["NJ", UsState.newJersey],
    ["new mexico", UsState.newMexico],
    ["NM", UsState.newMexico],
    ["new york", UsState.newYork],
    ["NY", UsState.newYork],
    ["north carolina", UsState.northCarolina],
    ["NC", UsState.northCarolina],
    ["north dakota", UsState.northDakota],
    ["ND", UsState.northDakota],
    ["ohio", UsState.ohio],
    ["OH", UsState.ohio],
    ["oklahoma", UsState.oklahoma],
    ["OK", UsState.oklahoma],
    ["oregon", UsState.oregon],
    ["OR", UsState.oregon],
    ["pennsylvania", UsState.pennsylvania],
    ["PA", UsState.pennsylvania],
    ["puerto rico", UsState.puertoRico],
    ["PR", UsState.puertoRico],
    ["palau", UsState.palau],
    ["PW", UsState.palau],
    ["rhode island", UsState.rhodeIsland],
    ["RI", UsState.rhodeIsland],
    ["south carolina", UsState.southCarolina],
    ["SC", UsState.southCarolina],
    ["south dakota", UsState.southDakota],
    ["SD", UsState.southDakota],
    ["tennessee", UsState.tennessee],
    ["TN", UsState.tennessee],
    ["texas", UsState.texas],
    ["TX", UsState.texas],
    ["utah", UsState.utah],
    ["UT", UsState.utah],
    ["vermont", UsState.vermont],
    ["VT", UsState.vermont],
    ["virginia", UsState.virginia],
    ["VA", UsState.virginia],
    ["virgin islands", UsState.virginIslands],
    ["VI", UsState.virginIslands],
    ["washington", UsState.washington],
    ["WA", UsState.washington],
    ["west virginia", UsState.westVirginia],
    ["WV", UsState.westVirginia],
    ["wisconsin", UsState.wisconsin],
    ["WI", UsState.wisconsin],
    ["wyoming", UsState.wyoming],
    ["WY", UsState.wyoming],
]);
function formatCurrency(value) {
    return new Intl.NumberFormat("en", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 0,
    }).format(value);
}
exports.formatCurrency = formatCurrency;
// if the absolute value of a number is >= 1000, convert it to kilo units (e.g. 100,000 to 100)
// Math.round(Math.abs(num)/100)/10 instead of (Math.abs(num)/1000).toFixed(1) so typescript is happy
function toKiloUnits(num) {
    return Math.abs(num) > 999
        ? Math.sign(num) * (Math.round(Math.abs(num) / 100) / 10)
        : Math.sign(num) * Math.abs(num);
}
exports.toKiloUnits = toKiloUnits;
// checks the luxonDate to see if it's in the past (before today), if it is, it'll return a date
// that is current day + numDays days, otherwise it will return the luxonDate as is
function resetPastLuxonDateToFutureDate(luxonDate, numDays = 0) {
    return luxonDate.toUTC().startOf("day") < luxon_1.DateTime.utc().startOf("day")
        ? luxon_1.DateTime.utc().plus({ days: numDays })
        : luxonDate;
}
exports.resetPastLuxonDateToFutureDate = resetPastLuxonDateToFutureDate;
function resetPastStringDateToFutureISODate(stringDate, numDays = 0) {
    return resetPastLuxonDateToFutureDate(luxon_1.DateTime.fromJSDate(new Date(stringDate)), numDays).toISODate();
}
exports.resetPastStringDateToFutureISODate = resetPastStringDateToFutureISODate;
