"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateOneLoanData = exports.getEffectivePriorAddress = exports._findEffectivePriorAddressFromOneLoanData = exports.validateBorrower = exports.extractCoBorrowerRelation = exports.extractMaritalStatus = exports.validateLeadData = exports.setPurchasePriceAmount = exports.mapOneLoanToMyAccountHomeLead = void 0;
const non_normal_types_1 = require("./non-normal-types");
const grins_utils_1 = require("grins-utils");
const data_model_1 = require("./data-model");
/*
  NOTE: although we are practically guaranteed that `<oneloan_data_object>.loan.applications[0]` will exist and be the
  one to be used, we should probably have a check for that in all the code below.
*/
function mapOneLoanToMyAccountHomeLead(oneLoanData) {
    const { myAccountApplicant, myAccountCoApplicant, hmda, loanProductData, streetName, streetNum, unitNumber, } = validateOneLoanData(oneLoanData);
    const effectivePriorAddress = getEffectivePriorAddress(oneLoanData);
    const results = {
        applicant: {
            firstName: myAccountApplicant.firstName,
            lastName: myAccountApplicant.lastName,
            dob: new Date(myAccountApplicant.birthDate),
            maritalStatus: extractMaritalStatus(myAccountApplicant.maritalStatusType),
            phone: myAccountApplicant.homePhoneNumber.trim() === ""
                ? myAccountApplicant.mobilePhone
                : myAccountApplicant.homePhoneNumber,
            relation: non_normal_types_1.OmsiteApplicantRelation.other, // doesn't exist in OneLoan data from our reqs doc, so default to other.
        },
        address: {
            streetNumber: streetNum,
            streetName: streetName,
            aptSuiteNumber: unitNumber,
            city: hmda.hmdaPropertyCity || "",
            state: hmda.hmdaPropertyState || "",
            zip: hmda.hmdaPropertyZipCode || "",
        },
        dateOfPurchase: new Date(loanProductData.loanScheduledClosingDate),
        leadId: "",
        purchasePriceAmount: setPurchasePriceAmount(oneLoanData.loan.purchasePriceAmount, oneLoanData.loan.property?.loanPurposeType),
        ...(effectivePriorAddress && { effectivePriorAddress }),
    };
    if (myAccountCoApplicant) {
        results.coApplicant = {
            firstName: myAccountCoApplicant.firstName,
            lastName: myAccountCoApplicant.lastName,
            dob: new Date(myAccountCoApplicant.birthDate),
            maritalStatus: extractMaritalStatus(myAccountCoApplicant.maritalStatusType),
            relation: extractCoBorrowerRelation(oneLoanData.loan.applications
                ? oneLoanData.loan.applications[0]
                : undefined),
        };
    }
    return results;
}
exports.mapOneLoanToMyAccountHomeLead = mapOneLoanToMyAccountHomeLead;
function setPurchasePriceAmount(purchasePriceAmount, loanPurposeType) {
    const result = loanPurposeType === non_normal_types_1.LoanPurposeType.purchase
        ? purchasePriceAmount
        : undefined;
    return result;
}
exports.setPurchasePriceAmount = setPurchasePriceAmount;
function validateLeadData(borrower, streetNum, streetName, hmda) {
    if (!borrower.firstName || borrower.firstName.trim() === "")
        throw new Error(`First name is missing.`);
    if (!borrower.lastName || borrower.lastName.trim() === "")
        throw new Error(`Last name is missing.`);
    if (!streetNum || streetNum.trim() === "")
        throw new Error(`Street number is missing from address.`);
    if (!streetName || streetName.trim() === "")
        throw new Error(`Street name is missing from address.`);
    // not checking hmda.hmdaPropertyAddress because it gets checked prior to this.
    if (!hmda.hmdaPropertyCity || hmda.hmdaPropertyCity.trim() === "")
        throw new Error(`Property city is missing.`);
    if (!hmda.hmdaPropertyState || hmda.hmdaPropertyState.trim() === "")
        throw new Error(`Property state is missing.`);
    if (!hmda.hmdaPropertyZipCode || hmda.hmdaPropertyZipCode.trim() === "")
        throw new Error(`Property zip code is missing.`);
}
exports.validateLeadData = validateLeadData;
/*
  extractMaritalStatus() implements business rules from https://wiki.guaranteedrate.com/x/HJWzBg
*/
function extractMaritalStatus(maritalStatusType) {
    if (maritalStatusType.trim() === "")
        return data_model_1.GrinsMaritalStatus.single;
    const maritalStatus = data_model_1.GrinsMaritalStatusMap.get(maritalStatusType.toLowerCase()) ||
        data_model_1.GrinsMaritalStatus.single;
    return maritalStatus;
}
exports.extractMaritalStatus = extractMaritalStatus;
/*
  extractCoBorrowerRelation() implements business rules from https://wiki.guaranteedrate.com/x/HJWzBg
*/
function extractCoBorrowerRelation(oneLoanApplication) {
    if (!oneLoanApplication) {
        throw new Error(`OneLoan application is missing.`);
    }
    const borrowerMaritalStatus = data_model_1.GrinsMaritalStatusMap.get(oneLoanApplication.borrower?.maritalStatusType?.toLowerCase() || "");
    const coBorrowerMaritalStatus = data_model_1.GrinsMaritalStatusMap.get(oneLoanApplication.coborrower?.maritalStatusType?.toLowerCase() || "");
    if (!borrowerMaritalStatus || !coBorrowerMaritalStatus) {
        return non_normal_types_1.OmsiteApplicantRelation.other;
    }
    else if (borrowerMaritalStatus === data_model_1.GrinsMaritalStatus.married &&
        coBorrowerMaritalStatus === data_model_1.GrinsMaritalStatus.married) {
        return non_normal_types_1.OmsiteApplicantRelation.spouse;
    }
    else {
        return non_normal_types_1.OmsiteApplicantRelation.other;
    }
}
exports.extractCoBorrowerRelation = extractCoBorrowerRelation;
function validateBorrower(borrower, asWhichBorrower) {
    if (!borrower.firstName || borrower.firstName.trim() === "")
        throw new Error(`${asWhichBorrower} firstName data is missing or blank.`);
    if (!borrower.lastName || borrower.lastName.trim() === "")
        throw new Error(`${asWhichBorrower} lastName data is missing or blank.`);
    if (!borrower.birthDate || borrower.birthDate.trim() === "")
        throw new Error(`${asWhichBorrower} birthdate data is missing or blank.`);
    if (!borrower.maritalStatusType || borrower.maritalStatusType.trim() === "")
        throw new Error(`${asWhichBorrower} marital status data is missing or blank.`);
    const bothPhonesAreUndefined = !borrower.homePhoneNumber && !borrower.mobilePhone;
    const bothPhonesAreBlank = borrower.homePhoneNumber?.trim() === "" &&
        borrower.mobilePhone?.trim() === "";
    if (bothPhonesAreUndefined || bothPhonesAreBlank)
        throw new Error(`${asWhichBorrower} mobile phone and home phone are both missing or blank.`);
    const onePhoneBlankTheOtherUndefined = (borrower.homePhoneNumber?.trim() === "" && !borrower.mobilePhone) ||
        (borrower.mobilePhone?.trim() === "" && !borrower.homePhoneNumber);
    if (onePhoneBlankTheOtherUndefined)
        throw new Error(`${asWhichBorrower} mobile phone and home phone are either missing or blank.`);
    const homePhoneNumber = borrower.homePhoneNumber || "";
    const mobilePhone = borrower.mobilePhone || "";
    return {
        firstName: borrower.firstName,
        lastName: borrower.lastName,
        birthDate: borrower.birthDate,
        homePhoneNumber,
        mobilePhone,
        maritalStatusType: borrower.maritalStatusType || "",
    };
}
exports.validateBorrower = validateBorrower;
/**
 * @summary Determines and returns which address in OneLoan data to use as effective, prior address.
 * @param residences Array of residences from OneLoan
 * @returns {Residence}
 */
function _findEffectivePriorAddressFromOneLoanData(residences, loanType = non_normal_types_1.LoanPurposeType.other) {
    const loanIsPurchase = loanType === non_normal_types_1.LoanPurposeType.purchase;
    if (loanIsPurchase) {
        const currentAddress = residences.find(residence => residence.residencyType === non_normal_types_1.ResidenceType.current);
        const currentAddressNotFound = !currentAddress;
        if (currentAddressNotFound) {
            throw new Error(`LoanType is Purchase, but currentAddress is unavailable.`);
        }
        return currentAddress;
    }
    const priorAddress = residences.find(residence => residence.residencyType === non_normal_types_1.ResidenceType.prior) || null;
    return priorAddress;
}
exports._findEffectivePriorAddressFromOneLoanData = _findEffectivePriorAddressFromOneLoanData;
/**
 * @summary Extracts a OneLoan address for use as prior address
 * @description There are use cases where no prior addresses will exist in OneLoan data.  So, in these cases,
 * we are to use the current address as an effective prior address.  If, however, prior address does exist, then
 * obviously that one will be extracted.
 * @param oneLoanData self-explanatory
 * @returns effective prior address (see fcn sig for details)
 */
function getEffectivePriorAddress(oneLoanData) {
    if (!oneLoanData.loan.applications ||
        !oneLoanData.loan.applications[0].residences) {
        throw new Error(`OneLoan data does not have any addresses.`);
    }
    const residences = oneLoanData.loan.applications[0].residences;
    const loanType = oneLoanData.loan.property?.loanPurposeType ?? non_normal_types_1.LoanPurposeType.other;
    const effectivePriorAddressFromOneLoan = _findEffectivePriorAddressFromOneLoanData(residences, loanType);
    if (!effectivePriorAddressFromOneLoan) {
        return null;
    }
    if (!effectivePriorAddressFromOneLoan.addressStreetLine1 ||
        effectivePriorAddressFromOneLoan.addressStreetLine1.trim() === "") {
        return null;
    }
    if (!effectivePriorAddressFromOneLoan.addressCity ||
        effectivePriorAddressFromOneLoan.addressCity.trim() === "") {
        return null;
    }
    if (!effectivePriorAddressFromOneLoan.addressState ||
        effectivePriorAddressFromOneLoan.addressState.trim() === "") {
        return null;
    }
    if (!effectivePriorAddressFromOneLoan.addressPostalCode ||
        effectivePriorAddressFromOneLoan.addressPostalCode.trim() === "") {
        return null;
    }
    const { streetName, streetNum, unitNumber } = (0, grins_utils_1.parseAddressThrowNoErrors)(effectivePriorAddressFromOneLoan.addressStreetLine1);
    return {
        streetNumber: streetNum,
        streetName: streetName,
        unitNumber,
        city: effectivePriorAddressFromOneLoan.addressCity,
        state: effectivePriorAddressFromOneLoan.addressState,
        zipCode5: effectivePriorAddressFromOneLoan.addressPostalCode,
    };
}
exports.getEffectivePriorAddress = getEffectivePriorAddress;
function validateOneLoanData(oneLoanData) {
    // not abstracting the below if-statements so that Typescript/lints are satisfied
    if (!oneLoanData.loan.applications ||
        oneLoanData.loan.applications.length < 1)
        throw new Error(`OneLoan data is missing application data.`);
    if (!oneLoanData.loan.applications[0].borrower)
        throw new Error(`Borrower is missing from OneLoan data.`);
    const myAccountApplicant = validateBorrower(oneLoanData.loan.applications[0].borrower, "Borrower");
    if (!oneLoanData.loan.hmda)
        throw new Error(`HMDA data is missing from OneLoan data.`);
    const hmda = oneLoanData.loan.hmda;
    if (!oneLoanData.loan.loanProductData)
        throw new Error(`Loan Product is missing from OneLoan data.`);
    const loanProductData = oneLoanData.loan.loanProductData;
    if (!hmda.hmdaPropertyAddress || hmda.hmdaPropertyAddress.trim() === "")
        throw new Error(`Address is missing from OneLoan data.`);
    const { streetName, streetNum, unitNumber } = (0, grins_utils_1.parseAddress)(hmda.hmdaPropertyAddress);
    validateLeadData(myAccountApplicant, streetNum, streetName, hmda);
    const effectivePriorAddress = getEffectivePriorAddress(oneLoanData);
    const results = {
        myAccountApplicant,
        hmda,
        loanProductData,
        streetName,
        streetNum,
        unitNumber,
        ...(effectivePriorAddress && { effectivePriorAddress }),
    };
    const coborrower = oneLoanData.loan.applications[0].coborrower;
    if (coborrower && !coborrower.noCoApplicantIndicator) {
        results.myAccountCoApplicant = validateBorrower(coborrower, "CoBorrower");
    }
    return results;
}
exports.validateOneLoanData = validateOneLoanData;
