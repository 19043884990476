"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokenTypes = exports.MatchAction = exports.MatchObjectType = exports.EspEventEnv = exports.CompanyName = exports.ResponseError = void 0;
class ResponseError extends Error {
    response;
    constructor(message, response) {
        super(message);
        this.response = response;
    }
}
exports.ResponseError = ResponseError;
var CompanyName;
(function (CompanyName) {
    CompanyName["gri"] = "gri";
    CompanyName["prate"] = "prate";
    CompanyName["op"] = "op";
    CompanyName["citywide"] = "citywide";
    CompanyName["certainty"] = "certainty";
    CompanyName["owng"] = "owng";
})(CompanyName || (exports.CompanyName = CompanyName = {}));
var EspEventEnv;
(function (EspEventEnv) {
    EspEventEnv["griDev"] = "dev";
    EspEventEnv["griProd"] = "prod";
    EspEventEnv["prateDev"] = "prate-dev";
    EspEventEnv["prateProd"] = "prate-prod";
    EspEventEnv["citywideDev"] = "citywide-dev";
    EspEventEnv["citywideProd"] = "citywide-prod";
    EspEventEnv["opDev"] = "op-dev";
    EspEventEnv["opProd"] = "op-prod";
})(EspEventEnv || (exports.EspEventEnv = EspEventEnv = {}));
var MatchObjectType;
(function (MatchObjectType) {
    MatchObjectType["sfContact"] = "sfContact";
    MatchObjectType["sfLead"] = "sfLead";
    MatchObjectType["grinsLead"] = "grinsLead";
})(MatchObjectType || (exports.MatchObjectType = MatchObjectType = {}));
var MatchAction;
(function (MatchAction) {
    MatchAction["created"] = "created";
    MatchAction["archived"] = "archived";
    MatchAction["merged"] = "merged";
    MatchAction["error"] = "error";
})(MatchAction || (exports.MatchAction = MatchAction = {}));
var TokenTypes;
(function (TokenTypes) {
    TokenTypes["okta"] = "okta";
    TokenTypes["jarvis"] = "jarvis";
    TokenTypes["salesforce"] = "salesforce";
})(TokenTypes || (exports.TokenTypes = TokenTypes = {}));
