"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertEzLynxStatusToQuoteStatus = exports.parseEzLynxMultiCoverageValue = exports.parseEzLynxSingleCoverageValue = exports.convertEzLynxQuoteResponseToAutoQuote = exports.convertEzLynxQuoteResponseToHomeQuote = void 0;
const grins_utils_1 = require("grins-utils");
const index_1 = require("./index");
const non_normal_types_1 = require("./non-normal-types");
function convertEzLynxQuoteResponseToHomeQuote(rawDetails, logoUrl, carriers, homeInput) {
    const carrierData = (0, index_1.getCarrierData)(rawDetails.Carrier["@_ID"], carriers);
    const result = {
        quoteId: rawDetails.QuoteExecutionID.toString(),
        carrierId: rawDetails.Carrier["@_ID"],
        carrierName: carrierData ? carrierData.name : "carrier-not-found",
        carrierLogo: carrierData ? logoUrl + carrierData.logo_url : "",
        agentUrl: "",
        consumerUrl: "",
        status: convertEzLynxStatusToQuoteStatus(rawDetails.Status),
        statusMessage: rawDetails.StatusText,
        costPerYear: rawDetails.EZQuoteResult?.EZQuote.PremiumDetails.Amount || 0,
        deductible: 0,
        dwellingCoverage: 0,
        otherStructuresCoverage: 0,
        personalPropertyCoverage: 0,
        lossOfUseCoverage: 0,
        personalLiabilityCoverage: 0,
        medicalPaymentsCoverage: 0,
        term: rawDetails.EZQuoteResult?.EZQuote.PremiumDetails.Term || 0,
        quoteType: non_normal_types_1.QuoteServicePolicyType.ho3,
        tier: null,
        bridgeQuote: false,
        externalId: rawDetails.QuoteExecutionID.toString(),
        effectiveDate: (0, grins_utils_1.resetPastStringDateToFutureISODate)(homeInput.insured_property.purchase_date, 7) || "",
    };
    rawDetails.EZQuoteResult?.EZQuote.Coverages.Coverage.forEach(coverage => {
        const name = coverage["@_name"];
        if (name === "Deductible")
            result.deductible = parseEzLynxSingleCoverageValue(coverage.value);
        else if (name === "Dwelling")
            result.dwellingCoverage = parseEzLynxSingleCoverageValue(coverage.value);
        else if (name === "OtherStructures")
            result.otherStructuresCoverage = parseEzLynxSingleCoverageValue(coverage.value);
        else if (name === "PersonalProperty")
            result.personalPropertyCoverage = parseEzLynxSingleCoverageValue(coverage.value);
        else if (name === "LossOfUse")
            result.lossOfUseCoverage = parseEzLynxSingleCoverageValue(coverage.value);
        else if (name === "PersonalLiability")
            result.personalLiabilityCoverage = parseEzLynxSingleCoverageValue(coverage.value);
        else if (name === "MedicalPayments")
            result.medicalPaymentsCoverage = parseEzLynxSingleCoverageValue(coverage.value);
    });
    return result;
}
exports.convertEzLynxQuoteResponseToHomeQuote = convertEzLynxQuoteResponseToHomeQuote;
function convertEzLynxQuoteResponseToAutoQuote(rawDetails, logoUrl, carriers, autoInput) {
    let monthlyPremium = 0;
    let premiumTerm = 0;
    if (rawDetails.EZQuoteResult?.EZQuote) {
        const totalPremium = rawDetails.EZQuoteResult.EZQuote.PremiumDetails.TotalPremium;
        premiumTerm = rawDetails.EZQuoteResult.EZQuote.PremiumDetails.Term;
        monthlyPremium = totalPremium / premiumTerm;
    }
    const carrierData = (0, index_1.getCarrierData)(rawDetails.Carrier["@_ID"], carriers);
    const result = {
        quoteId: rawDetails.QuoteExecutionID.toString(),
        carrierId: rawDetails.Carrier["@_ID"],
        carrierName: carrierData ? carrierData.name : "carrier-not-found",
        carrierLogo: carrierData ? logoUrl + carrierData.logo_url : "",
        agentUrl: "",
        consumerUrl: "",
        status: convertEzLynxStatusToQuoteStatus(rawDetails.Status),
        statusMessage: rawDetails.StatusText,
        monthlyPremium,
        bodilyInjuryCoverage: { low: 0, high: 0 },
        propertyDamageCoverage: 0,
        medicalCoverage: 0,
        uninsuredMotoristCoverage: { low: 0, high: 0 },
        comprehensiveDeductible: 0,
        collisionDeductible: 0,
        term: premiumTerm,
        quoteType: non_normal_types_1.QuoteServicePolicyType.auto,
        tier: null,
        bridgeQuote: false,
        externalId: rawDetails.QuoteExecutionID.toString(),
        effectiveDate: (0, grins_utils_1.resetPastStringDateToFutureISODate)(autoInput.property.purchase_date, 7) ||
            "",
    };
    rawDetails.EZQuoteResult?.EZQuote.Coverages.Coverage.forEach(coverage => {
        const name = coverage["@_name"];
        if (name === "BI")
            result.bodilyInjuryCoverage = parseEzLynxMultiCoverageValue(coverage.value);
        else if (name === "PD")
            result.propertyDamageCoverage = parseEzLynxSingleCoverageValue(coverage.value);
        else if (name === "MP")
            result.medicalCoverage = parseEzLynxSingleCoverageValue(coverage.value);
        else if (name === "UM")
            result.uninsuredMotoristCoverage = parseEzLynxMultiCoverageValue(coverage.value);
    });
    const vehiclePremiums = rawDetails.EZQuoteResult?.EZQuote?.VehicleCoveragePremiums;
    const rawVehicles = vehiclePremiums?.Vehicle;
    const vehicles = Array.isArray(rawVehicles) ? rawVehicles : [rawVehicles];
    const vehicle = vehicles[0];
    if (vehicle) {
        const premiums = vehicle.CoveragePremium;
        premiums?.forEach(premium => {
            const name = premium["@_name"];
            if (name === "Comprehensive")
                result.comprehensiveDeductible = parseEzLynxSingleCoverageValue(premium.Limits);
            else if (name === "Collision")
                result.collisionDeductible = parseEzLynxSingleCoverageValue(premium.Limits);
        });
    }
    return result;
}
exports.convertEzLynxQuoteResponseToAutoQuote = convertEzLynxQuoteResponseToAutoQuote;
function parseEzLynxSingleCoverageValue(value) {
    if (typeof value === "number")
        return value;
    // Parses a currency string, possibly with cents (e.g. "$123,456.40")
    const digits = value.replaceAll(/[^\d.]/g, "");
    return Number.parseInt(digits);
}
exports.parseEzLynxSingleCoverageValue = parseEzLynxSingleCoverageValue;
function parseEzLynxMultiCoverageValue(value) {
    if (typeof value === "number")
        return { low: value, high: value };
    const [lowStr, highStr] = value.split("/");
    const low = Number.parseInt(lowStr) * 1000;
    const high = Number.parseInt(highStr) * 1000;
    return { low, high };
}
exports.parseEzLynxMultiCoverageValue = parseEzLynxMultiCoverageValue;
function convertEzLynxStatusToQuoteStatus(ezlStatus) {
    if ([
        non_normal_types_1.EZLQuoteExecutionStatusEnum.failed,
        non_normal_types_1.EZLQuoteExecutionStatusEnum.failedWaiting,
        non_normal_types_1.EZLQuoteExecutionStatusEnum.submissionFailure,
    ].includes(ezlStatus))
        return non_normal_types_1.QuoteStatus.Failed;
    else if ([non_normal_types_1.EZLQuoteExecutionStatusEnum.succeeded].includes(ezlStatus))
        return non_normal_types_1.QuoteStatus.Succeeded;
    else
        return non_normal_types_1.QuoteStatus.Unknown;
}
exports.convertEzLynxStatusToQuoteStatus = convertEzLynxStatusToQuoteStatus;
